<template>
  <div class="card" style="background-color: whitesmoke;" id="mycard">
    <div class="card-header">
      <div class="card-title"> <h5 class="label-success  text-teal-800">Category Wise Books</h5> </div>
    </div>
    <div class="card-body">
      <div id="myChart" style="min-height: 300px;"></div>
    </div>
  </div>
</template>

<script>
import { store } from '@/store/store.js';
import { userService } from '@/store/auth-header.js';
export default {
  name: 'PieChart',
  store,
  data(){
    return {
      myChart:{},
      mydata:[],
    }
  },
  props:{
    title :{
      type : String,
      default : ()=> 'Pie chart',
    },
    chart_data:{
      type : Array,
      default : ()=> [],
    }
  },
  beforeMount () {
    //this.mydata = this.$props.chart_data;
  },
  mounted () {
    const self = this;
    this.load_book_categories();
    this.draw_chart();
  },
  methods:{
    draw_chart(){
      const self = this;

      self.$data.myChart = echarts.init(document.getElementById('myChart'), null, {
        renderer: 'canvas',
        useDirtyRect: false
      });

      let option = {
        // title: {
        //   // text: self.$props.title,
        //   // subtext: 'Fake Data',
        //   left: 'center'
        // },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'bottom',
        //   left: 'left'
        // },
        series: [
          {
            name: self.$props.title,
            type: 'pie',
            radius: '50%',
            data: self.mydata,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      if (option && typeof option === 'object') {
        self.$data.myChart.setOption(option);
      }
    },
    load_book_categories(){
      const self = this;

      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      //fetch the Data
      self.$data.mydata = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/dashboard/category_book_count`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if(_.isArray(resp.data)){
            self.$data.mydata = resp.data;
            self.draw_chart();
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      }).finally(()=>{
        $('#mycard').unblock();
      });


    },
  }
}
</script>

<style scoped>

</style>
